import momemt from 'moment'
jQuery(document).ready(function ($) {
	$.ajax({
		url: 'https://rest.bandsintown.com/artists/Corey%20Kent/events?app_id=45PRESS_COREY_KENT',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert('Error fetching events!')
		},
		success: (data) => {
			const events = $('#events')
			let html = ''
			if (data.length) {
				for (let event of data) {
					let location = event.venue.city + ', ' + event.venue.region
					if (location === ', ') {
						location = ''
					}
					if (event.venue.name.toLowerCase() === 'streaming live') {
						location = 'Online'
					}
					html += '<div class="event">'
					html += '<div class="event-info">'
					html +=
						'<div class="event-date">' +
						momemt(event.datetime).format('ddd, MMM D') +
						'</div>'
					html +=
						'<div class="event-location">' +
						location +
						' | ' +
						event.venue.name +
						'</div>'
					html += '</div>'
					html += '<div class="event-links">'
					for (let offer of event.offers) {
						html +=
							'<a href="' +
							offer.url +
							'" target="_blank" class="btn btn-black-fill">' +
							offer.type +
							'</a>'
					}
					html += '</div>'
					html += '</div>'
				}
				events.html(html)
			} else {
				events.html('No upcoming events.')
			}
		}
	})
	// show all dates
	$('.all-dates-trigger').click(function (e) {
		e.preventDefault()
		$('#events').toggleClass('all')
		if ($(this).html() === 'show all dates') {
			$(this).html('show less')
		} else {
			$(this).html('show all dates')
		}
	})
	// Sliders
	$('.slider-music-featured').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		asNavFor: '.slider-music-index'
	})
	$('.slider-music-index').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: '.slider-music-featured',
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4
				}
			}
		]
	})
	$('.slider-videos-index').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow:
			'<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
		nextArrow:
			'<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
		mobileFirst: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3
				}
			}
		]
	})
	// Video section
	const featuredVideo = $('.featured-video iframe')
	// const featuredTitle = $('.featured-video .title')
	$('.slider-video-item').click(function (e) {
		e.preventDefault()
		const thisVideo = e.currentTarget
		const thisVideoID = thisVideo.getAttribute('data-yt-video-id')
		// const thisVideoTitle = thisVideo.getAttribute('data-title')
		featuredVideo.attr('src', `https://www.youtube.com/embed/${thisVideoID}`)
		// featuredTitle.html(thisVideoTitle)
	})

	// add opening tag before an specific element
	$('.music-video-wrapper').before('<section id="music">')
})
